import React from "react";
import Contact from "./Components/Contact";
import MainPage from "./Components/MainPage";
import Faq from "./Components/Faq";
import OurStory from "./Components/OurStory";
import SignUp from "./Components/SignUp";
import Login from "./Components/Login";
import VerifyAccount from "./Components/VerifyAccount";
import AccountVerified from "./Components/AccountVerified";
import { connect } from "react-redux";
import PrivateRoute from "./Components/PrivateRoute";
import Dashboard from "./Components/Dashboard";
import ProductsPage from "./Components/ProductsPage";
import MyCart from "./Components/MyCart";
import PageLink from "./Components/PageLink";
import DeliveryLocations from "./Components/DeliveryLocations";
import Checkout from "./Components/Checkout";
import OrderReceived from "./Components/OrderReceived";
import Addresses from "./Components/Addresses";
import OrderDetails from "./Components/OrderDetails";
import ResetPassword from "./Components/ResetPassword";
import NewResetPassword from "./Components/NewResetPassword";
import ElginOrganic from "./Components/ElginOrganic";
import { resetProducts } from "../src/actions/productsActions";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import Topbar from "./Components/Topbar";
import NotFound from "./Components/NotFound";
import { Switch, Route, withRouter } from "react-router-dom";
import PrivateAdminRoute from "./Components/PrivateAdminRoute";
import Coupon from "./Components/AdminPanel/Coupon";
import AdminDash from "./Components/AdminPanel/AdminDash";
import Recipes from "./Components/Recipes";
import RecipesDashBoard from "./Components/AdminPanel/RecipesDashBoard";
import ProductsDashboard from "./Components/AdminPanel/ProductsDashboard";
import NewProduct from "./Components/AdminPanel/NewProduct";
import UpdateProduct from "./Components/AdminPanel/UpdateProduct";
import NewRecipe from "./Components/AdminPanel/NewRecipe";
import UpdateRecipe from "./Components/AdminPanel/UpdateRecipe";
import RecipeLink from "./Components/RecipeLink";
import UserDashboard from "./Components/AdminPanel/UserDashboard";
import SelectedUser from "./Components/AdminPanel/SelectedUser";
import OrdersDashboard from "./Components/AdminPanel/OrdersDashboard";
import SelectedOrder from "./Components/AdminPanel/SelectedOrder";
import FaqsDashboard from "./Components/AdminPanel/FaqsDashboard";
import NewFaq from "./Components/AdminPanel/NewFaq";
import SelectedFaq from "./Components/AdminPanel/SelectedFaq";
import NewCoupon from "./Components/AdminPanel/NewCoupon";
import { resetLocalStorageUser } from "../src/actions/formActions";
import Reviews from "./Components/AdminPanel/Reviews";
import BlogsDashboard from "./Components/AdminPanel/BlogsDashboard";
import NewBlog from "./Components/AdminPanel/NewBlog";
import Blogs from "./Components/Blogs";
import Article from "./Components/Article";
import ShippingPolicy from "./Components/ShippingPolicy";

class Routes extends React.Component {
  state = {
    navHeight: 0,
    windowWidth: window.innerWidth,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.auth.checkUser.userName !== null) {
        if (!localStorage.jwtToken) {
          this.props.resetLocalStorageUser();
        }
      }

      this.updateWindowDimension();
      let pathnames = this.props.location.pathname.split("/");
      if (!pathnames.includes("shop") && !this.props.products.reset) {
        this.props.resetProducts();
      }
    }
  }

  componentDidMount() {
    this.updateWindowDimension();
    window.addEventListener("resize", this.updateWindowDimension);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimension);
  }

  updateWindowDimension = (e) => {
    this.setState({
      windowWidth: window.innerWidth,
    });

    if (document.getElementsByClassName("header-top")[0]) {
      const navbarHeight =
        document.getElementsByClassName("header-top")[0].clientHeight;
      const navCollapseHeight =
        document.getElementById("nav-collapse").clientHeight;

      let totalHeight;

      if (this.state.windowWidth < 768) {
        totalHeight = navbarHeight - navCollapseHeight;
      } else {
        totalHeight = navbarHeight;
      }

      this.setState({
        navHeight: totalHeight,
      });
    }
  };

  render() {
    let showNav = true;

    let pathnamesArray = this.props.history.location.pathname.split("/");

    if (pathnamesArray.includes("adminpanel")) {
      showNav = false;
    }
    if (this.props.history.location.pathname === "/checkout") {
      showNav = false;
    }

    let navHeight = this.state.navHeight + "px";

    return (
      <div
        style={{ display: "flex", minHeight: "100vh", flexDirection: "column" }}
      >
        {!this.props.fullPageLoading.fullPageLoading &&
          !this.props.notfound.notfound &&
          showNav && (
            <div className="fixed-top header-top ">
              <Topbar />
              <NavBar />
            </div>
          )}

        <div
          style={{
            flex: 1,
            paddingTop: !this.props.notfound.notfound && showNav && navHeight,
          }}
        >
          <Switch>
            <Route exact path="/" component={MainPage} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/faqs" component={Faq} />
            <Route exact path="/ourstory" component={OurStory} />
            <Route exact path="/elginorganic" component={ElginOrganic} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/checkout" component={Checkout} />
            <Route
              exact
              path="/deliverylocations"
              component={DeliveryLocations}
            />
            <Route exact path="/shop" component={ProductsPage} />
            <Route exact path="/shop/:title/:productid" component={PageLink} />
            <Route exact path="/recipes" component={Recipes} />
            <Route
              exact
              path="/recipes/:title/:recipeid"
              component={RecipeLink}
            />
            <Route exact path="/cart" component={MyCart} />
            <Route exact path="/verifyaccount" component={VerifyAccount} />
            <Route
              exact
              path="/verifyaccount/:code"
              component={AccountVerified}
            />
            <Route
              exact
              path="/newPassword/:userid/:code"
              component={NewResetPassword}
            />
            <Route
              exact
              path="/order-received/:orderid"
              component={OrderReceived}
            />
            <Route exact path="/delivery-policy" component={ShippingPolicy} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute
              exact
              path="/dashboard/addresses"
              component={Addresses}
            />
            <PrivateRoute
              exact
              path="/dashboard/order-details/:orderid"
              component={OrderDetails}
            />
            <PrivateAdminRoute exact path="/adminpanel" component={AdminDash} />
            <PrivateAdminRoute
              exact
              path="/adminpanel/coupons"
              component={Coupon}
            />
            <PrivateAdminRoute
              exact
              path="/adminpanel/recipes"
              component={RecipesDashBoard}
            />
            <PrivateAdminRoute
              exact
              path="/adminpanel/recipes/new-recipe"
              component={NewRecipe}
            />
            <PrivateAdminRoute
              exact
              path="/adminpanel/products"
              component={ProductsDashboard}
            />
            <PrivateAdminRoute
              exact
              path="/adminpanel/products/newProduct"
              component={NewProduct}
            />
            <PrivateAdminRoute
              exact
              path="/adminpanel/products/:title/:productid"
              component={UpdateProduct}
            />
            <PrivateAdminRoute
              exact
              path="/adminpanel/recipes/:title/:recipeid"
              component={UpdateRecipe}
            />
            <PrivateAdminRoute
              exact
              path="/adminpanel/users"
              component={UserDashboard}
            />
            <PrivateAdminRoute
              exact
              path="/adminpanel/users/:name/:userid"
              component={SelectedUser}
            />
            <PrivateAdminRoute
              exact
              path="/adminpanel/orders"
              component={OrdersDashboard}
            />
            <PrivateAdminRoute
              exact
              path="/adminpanel/orders/:orderid"
              component={SelectedOrder}
            />
            <PrivateAdminRoute
              exact
              path="/adminpanel/faqs"
              component={FaqsDashboard}
            />
            <PrivateAdminRoute
              exact
              path="/adminpanel/faqs/new-faq"
              component={NewFaq}
            />
            <PrivateAdminRoute
              exact
              path="/adminpanel/faqs/:faq_id"
              component={SelectedFaq}
            />
            <PrivateAdminRoute
              exact
              path="/adminpanel/coupons/newCoupon"
              component={NewCoupon}
            />
            <PrivateAdminRoute
              exact
              path="/adminpanel/reviews"
              component={Reviews}
            />
            <PrivateAdminRoute
              exact
              path="/adminpanel/blogs-dashboard"
              component={BlogsDashboard}
            />
            <PrivateAdminRoute
              exact
              path="/adminpanel/blogs-dashboard/new-blog"
              component={NewBlog}
            />
            <Route exact path="/blog" component={Blogs} />
            <Route exact path="/blogs/:articlename" component={Article} />
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
        {!this.props.notfound.notfound && showNav && <Footer />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notfound: state.notfound,
  auth: state.form,
  fullPageLoading: state.fullPageLoading,
  products: state.products,
});

export default connect(mapStateToProps, {
  resetProducts,
  resetLocalStorageUser,
})(withRouter(Routes));
