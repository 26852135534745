import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class Footer extends Component {
  render() {
    let date = new Date();

    return (
      this.props.location.pathname !== "/adminpanel" &&
      this.props.location.pathname !== "/checkout" && (
        <footer className=" font-small mdb-color pt-4 text-dark border px-3">
          <div className=" text-center text-md-left">
            <div className="row text-center text-md-left mt-3 pb-3">
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6
                  className="text-uppercase mb-4 font-weight-bold"
                  style={{ color: "rgb(14,35,87)" }}
                >
                  Frozberries
                </h6>
                <p style={{ color: "rgb(14,35,87)" }}>
                  Frozberries is a supplier of Australian Grown as well as
                  imported frozen berries, tropical fruit and fruit purees. We
                  are located in six Australian states delivering best quality
                  all year round.
                </p>
              </div>

              <hr className="w-100 clearfix d-md-none" />

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6
                  className="text-uppercase mb-4 font-weight-bold"
                  style={{ color: "rgb(14,35,87)" }}
                >
                  Useful links
                </h6>
                <p>
                  <a href="/" style={{ color: "rgb(14,35,87)" }}>
                    Products
                  </a>
                </p>
                <p>
                  <a href="/faqs" style={{ color: "rgb(14,35,87)" }}>
                    Faqs
                  </a>
                </p>
                <p>
                  <a href="/contact" style={{ color: "rgb(14,35,87)" }}>
                    Contact
                  </a>
                </p>
                <p>
                  <a href="/blog" style={{ color: "rgb(14,35,87)" }}>
                    Blog
                  </a>
                </p>
                <p>
                  <a href="/delivery-policy" style={{ color: "rgb(14,35,87)" }}>
                    Delivery Policy
                  </a>
                </p>
              </div>

              <hr className="w-100 clearfix d-md-none" />

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6
                  className="text-uppercase mb-4 font-weight-bold"
                  style={{ color: "rgb(14,35,87)" }}
                >
                  Contact
                </h6>
                <p style={{ color: "rgb(14,35,87)" }}>
                  <i
                    className="fas fa-home mr-3 "
                    style={{ color: "rgb(14,35,87)" }}
                  ></i>
                  11 Saligna Drive Tullamarine VIC 3043
                </p>
                <p style={{ color: "rgb(14,35,87)" }}>
                  <i
                    className="fas fa-envelope mr-3 "
                    style={{ color: "rgb(14,35,87)" }}
                  ></i>
                  <a href="mailto:sales@harvestimevic.com.au">
                    sales@harvestimevic.com.au
                  </a>
                </p>
                <p style={{ color: "rgb(14,35,87)" }}>
                  <i
                    className="fas fa-phone mr-3 "
                    style={{ color: "rgb(14,35,87)" }}
                  ></i>{" "}
                  <a href="tel:03 9335 6331">03 9335 6331</a>
                </p>
              </div>
            </div>
            <hr />

            {this.props.auth.isAuthenticated ? (
              <ul className="list-unstyled list-inline text-center py-2">
                <li className="list-inline-item">
                  <h5 className="mb-1" style={{ color: "rgb(14,35,87)" }}>
                    Browse our
                  </h5>
                </li>
                <li className="list-inline-item">
                  <a href="/shop" className="btn btn-danger btn-rounded">
                    Products
                  </a>
                </li>
              </ul>
            ) : (
              <ul className="list-unstyled list-inline text-center py-2">
                <li className="list-inline-item">
                  <h5 className="mb-1" style={{ color: "rgb(14,35,87)" }}>
                    Register for free
                  </h5>
                </li>
                <li className="list-inline-item">
                  <a href="/signup" className="btn btn-danger btn-rounded">
                    Sign up!
                  </a>
                </li>
              </ul>
            )}

            <hr />

            <div className="row d-flex align-items-center">
              <div className="col-12 text-center">
                <p style={{ color: "rgb(14,35,87)" }}>
                  © {date.getFullYear()} Copyright:
                  <a href="https://frozberriesvic.com.au/">
                    <strong style={{ color: "rgb(14,35,87)" }}>
                      {" "}
                      www.frozberriesvic.com.au
                    </strong>
                  </a>
                </p>
              </div>

              <div className="col-12">
                <div className="text-center ">
                  <ul className="list-unstyled list-inline">
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/FrozberriesVic/"
                        className="btn-floating btn-sm rgba-white-sdark mx-1"
                      >
                        <i
                          className="fab fa-facebook-f fa-2x"
                          style={{ color: "rgb(14,35,87)" }}
                        ></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/frozberries_vic/?hl=en"
                        className="btn-floating btn-sm rgba-white-sdark mx-1"
                      >
                        <i
                          className="fab fa-instagram fa-2x"
                          style={{ color: "rgb(14,35,87)" }}
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mb-2">
            <div
              className="fb-like"
              data-href="https://www.facebook.com/FrozberriesVic/"
              data-width=""
              data-layout="button_count"
              data-action="like"
              data-size="large"
              data-share="true"
            ></div>{" "}
          </div>
        </footer>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.form,
  cart: state.cart,
});

export default connect(mapStateToProps, {})(withRouter(Footer));
