import React, { Component } from "react";
import { Row, Col, Button, Form, Input, Collapse } from "reactstrap";
import { connect } from "react-redux";
import {
  setShippingMethod,
  setDate,
  setPickUpTime,
  sendPickUpData,
} from "../actions/checkOutActions";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from "react-router-dom";
import TimePicker from "react-bootstrap-time-picker";
import ButtonSpinner from "./ButtonSpinner";
import classnames from "classnames";
import Holidays from "date-holidays";

class Shipping extends Component {
  state = {
    total: 0,
    windowWidth: 0,
  };

  componentDidMount() {
    this.setState({
      windowWidth: window.innerWidth,
    });
    window.addEventListener("resize", this.updateWindowDimension);

    var total = (
      Math.round(
        (this.props.shoppingCart.cart.length !== 0
          ? this.props.shoppingCart.cart
              .map((eachItem) => eachItem.quantity * eachItem.product.price)
              .reduce((total, currentValue) => total + currentValue)
          : 0) * 100
      ) / 100
    ).toFixed(2);
    this.setState({
      total,
    });

    let time = new Date().getHours();

    if (time < 14 && time >= 10) {
      this.props.setPickUpTime(time * 3600);
    } else {
      this.props.setPickUpTime(10 * 3600);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkout.buttonLoading !== prevProps.checkout.buttonLoading
    ) {
      if (
        !this.props.checkout.buttonLoading &&
        Object.keys(this.props.checkout.errors).length === 0
      ) {
        this.props.nextStep();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimension);
  }
  updateWindowDimension = (e) => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  nextStep = (e) => {
    e.preventDefault();

    if (this.props.checkout.shippingMethod === "pickup") {
      let data = {
        date: this.props.checkout.date,
        time: this.props.checkout.pickupTime,
      };
      this.props.sendPickUpData(data);
    } else {
      this.props.nextStep();
    }
  };

  setShippingMethod = (e) => {
    this.props.setShippingMethod(e.target.name);
  };

  setDate = (date) => this.props.setDate(date);

  isPublicHoliday = (date) => {
    var hd = new Holidays("AU");

    return hd.isHoliday(date);
  };

  isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  isClosedDatesForPickups = (date) => {
    const closedDates = [
      "2024-12-19",
      "2024-12-20",
      "2024-12-21",
      "2024-12-22",
      "2024-12-23",
      "2024-12-24",
      "2024-12-25",
      "2024-12-26",
      "2024-12-27",
      "2024-12-28",
      "2024-12-29",
      "2024-12-30",
      "2024-12-31",
      "2025-01-01",
      "2025-01-02",
      "2025-01-03",
      "2025-01-04",
      "2025-01-05",
      "2025-01-06",
      "2025-01-07",
      "2025-01-08",
      "2025-01-09",
      "2025-01-10",
    ];

    return closedDates.includes(moment(date).format("YYYY-MM-DD"));
  };

  isClosedDatesForDelivery = () => {
    const closedDates = [
      "2024-12-19",
      "2024-12-20",
      "2024-12-21",
      "2024-12-22",
      "2024-12-23",
      "2024-12-24",
      "2024-12-25",
      "2024-12-26",
      "2024-12-27",
      "2024-12-28",
      "2024-12-29",
      "2024-12-30",
      "2024-12-31",
      "2025-01-01",
      "2025-01-02",
      "2025-01-03",
      "2025-01-04",
      "2025-01-05",
      "2025-01-06",
      "2025-01-07",
      "2025-01-08",
      "2025-01-09",
      "2025-01-10",
    ];

    return !closedDates.includes(moment(new Date()).format("YYYY-MM-DD"));
  };

  checkDate = (date) => {
    let weekday = this.isWeekday(date);
    let publicHoliday = this.isPublicHoliday(date);
    let closedDates = this.isClosedDatesForPickups(date);
    return weekday && !publicHoliday && !closedDates;
  };

  handleFieldChange = (e) => {
    this.props.goToStep(1);
    this.props.setShippingMethod(null);
  };

  handleTimeChange = (time) => {
    this.props.setPickUpTime(time);
  };

  render() {
    let time = new Date().getHours();
    var today = new Date();
    var isToday;
    if (this.props.checkout.date !== null) {
      isToday =
        today.toDateString() === this.props.checkout.date.toDateString();
    }

    return (
      <div className="ml-3">
        <div className="mx-3 py-3">
          <h5 className="mx-2 py-3 text-muted font-weight-bold">
            Shipping Method
          </h5>
          <div className="border ">
            <Row className="mx-3 py-3  border-bottom ">
              <Col xs="12">
                <Form className="d-inline pr-3">
                  <Input
                    type="radio"
                    name="pickup"
                    checked={this.props.checkout.shippingMethod === "pickup"}
                    onChange={this.setShippingMethod}
                  />
                </Form>
                Store Pick Up
                <p className="text-muted pl-3">
                  11 Saligna Dr, Tullamarine VIC 3043
                </p>
              </Col>

              <Collapse
                isOpen={this.props.checkout.shippingMethod === "pickup"}
              >
                <Col className="mx-3 py-3">
                  <p>Select a pickup date</p>
                  <DatePicker
                    selected={this.props.checkout.date}
                    onChange={this.setDate}
                    filterDate={this.checkDate}
                    placeholderText="Select a day"
                    minDate={moment().toDate()}
                    excludeDates={time >= 14 && [new Date()]}
                    className={classnames("form-control", {
                      "is-invalid": this.props.checkout.errors.date,
                    })}
                  />
                  <div className="invalid-feedback">
                    {this.props.checkout.errors.date}
                  </div>
                  <TimePicker
                    onChange={this.handleTimeChange}
                    value={this.props.checkout.pickupTime}
                    className={classnames("mt-3", {
                      "is-invalid": this.props.checkout.errors.time,
                    })}
                    start={isToday ? (time >= 14 ? "10" : `${time + 2}`) : "10"}
                    end="16"
                    step={60}
                  />
                  <div className="invalid-feedback">
                    {this.props.checkout.errors.time}
                  </div>
                </Col>
              </Collapse>
            </Row>
            {this.isClosedDatesForDelivery() && (
              <Row className="mx-3 py-3  ">
                <Col xs="9">
                  <Form className="d-inline pr-3">
                    <Input
                      type="radio"
                      checked={
                        this.props.checkout.shippingMethod === "delivery"
                      }
                      onChange={this.setShippingMethod}
                      name="delivery"
                    />
                  </Form>
                  Standard Shipping (1 to 3 days)
                </Col>
              </Row>
            )}
          </div>

          <div>
            <Row className="my-3">
              <Col>
                <Button
                  disabled={this.props.checkout.buttonLoading}
                  size="sm"
                  color="dark"
                  outline
                  className="border-0 font-weight-bold"
                  onClick={() => this.props.history.push("/cart")}
                >
                  &lt; Back to Cart
                </Button>
              </Col>

              <Col
                className="text-right"
                style={{ marginRight: this.state.windowWidth < 500 && 50 }}
              >
                <Button
                  size="sm"
                  disabled={
                    this.props.checkout.shippingMethod === null ||
                    (this.props.checkout.shippingMethod === "pickup" &&
                      (this.props.checkout.pickupTime === 0 ||
                        this.props.checkout.date === null))
                  }
                  color="success"
                  onClick={this.nextStep}
                  style={{ minWidth: 182 }}
                >
                  {this.props.checkout.buttonLoading ? (
                    <ButtonSpinner />
                  ) : (
                    <span> Continue to User Details &gt;</span>
                  )}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
  shoppingCart: state.cart,
  guest: state.guest,
});

export default connect(mapStateToProps, {
  setShippingMethod,
  setDate,
  setPickUpTime,
  sendPickUpData,
})(withRouter(Shipping));
