import React from "react";
import { Col, Row, Container } from "reactstrap";
import Base from "./Base";
import { Helmet } from "react-helmet";

const ShippingPolicy = () => {
  const policies = [
    "Orders are delivered on Monday to Friday between 8am and 4pm(excluding Public Holidays).",
    "Orders are delivered in our fully maintained frozen vehicle ensuring they remain frozen during transport and will arrive frozen.",
    "Being a frozen product, we require someone to be home to accept delivery.",
    "Orders are packed in a standard cardboard box and will not remain frozen if left outside of the freezer once delivered. We recommend you place your order in the freezer immediately to maintain temperature.",
    "Confirmation will be sent to you via our website advising the day of delivery. If you cannot accept delivery on your allocated day, please contact us on 93356331 prior to the delivery day to discuss alternative arrangements.",
    "Our driver will provide a courtesy phone call 30 minutes prior to delivery on your allocated delivery day.",
    "If we have had no confirmation that you will be unable to accept delivery on your allocated day, the delivery will proceed. If no-one is home when our driver attempts delivery, we will return your order to our warehouse. In this event, you may be subject to a $25.00 re-delivery fee.",
  ];
  return (
    <React.Fragment>
      <div>
        <Helmet>
          <title>Delivery Policy - Frozberries Victoria</title>
          <meta
            name="description"
            content="Delivery Policy - Frozberries Victoria"
          />
        </Helmet>
      </div>
      <Base title="Delivery Policy" />
      <div className="py-5">
        <Container>
          <Row className="">
            <Col>
              {policies.map((p, i) => (
                <p key={i}>{p}</p>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ShippingPolicy;
