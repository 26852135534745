import React, { Component } from "react";

export default class Messages extends Component {
  render() {
    return (
      <div className=" container">
        <div className=" text-left text-danger">
          <h5 className="text-left mt-4 px-2 mb-0 font-weight-bold">
            To our valued customers,
          </h5>
        </div>

        <div className="text-left text-danger">
          <h5 className="text-left mt-4 px-2 mb-0 font-weight-bold ">
            Frozberries Victoria online orders will close at 2pm on Friday
            19/12/2024 and will re-open on Friday 10/1/2025 at 8.00am
          </h5>

          <h5 className="text-left mt-4 px-2 mb-0 font-weight-bold ">
            We would like to take this opportunity to wish you and your staff a
            very Merry Christmas &amp; a safe and prosperous 2025
          </h5>

          <h5 className="text-left mt-4 px-2 mb-0 font-weight-bold">
            The Frozberries Team.
          </h5>
        </div>
      </div>
    );
  }
}
